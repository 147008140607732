<template>
  <v-form ref="form" lazy-validation>
    <h2 class="mt10 pt10">REMPLACER UN ZIP/FERMETURE</h2>
    <p>Nous remplaçons les zips par des zip identiques ou similaires, en fonction du stock disponible</p>
    <p>Pour les ZIPS spécifiques, nous ne sommes pas en mesure de les remplacer par ceux de la marque (ex : JOTT, KWAY,
      ...)</p>
    <p v-if="flux.level2 === 'SAC A DOS/SAC SOUPLE'">
      <v-icon>mdi-information-slab-circle-outline</v-icon>
      Nous ne remplaçons pas de zip sur sacs rigides / coqués, malettes PC...
    </p>
    <v-row v-if="labels">
      <v-col cols="12" sm="6">
        <div class="d-flex align-items-center justify-space-between" style="max-width: 350px">
          <picto :src="require('@/assets/pictos/zip.svg')" alt="Zip" position="top-left"/>
          <v-radio-group
            v-model="emplacement"
            :rules="requireRules"
          >
            <v-radio
              v-for="(label, index) in labels"
              :key="index"
              :label="label.label"
              :value="label.value"
              @click="getPrice()"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col v-if="displayPocheOptions" cols="12" sm="6">
        <h6 class="mt-md-10">Précisez l'emplacement</h6>
        <v-radio-group v-model="poche_emplacements" multiple row>
          <v-radio
            v-for="label in poche_emplacement_labels"
            :key="label"
            :label="label"
            :value="label"
            @click.prevent="onClickPocheEmplacement(label)"
          />
        </v-radio-group>
      </v-col>
      <v-col :sm="displayPocheOptions ? 12 : 6" class="d-flex flex-column pt-0 pt-sm-8" cols="12">
        <h6>{{ textAreaLabel }}</h6>
        <v-textarea
          v-model="autre_emplacement"
          name="input-7-4"
          outlined
          placeholder="Indiquez l'emplacement"
          rows="3"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col v-if="displayCursor || displayEtanche" class="pt-0" cols="12">
        <v-checkbox v-if="displayCursor" v-model="isCursor" @click="getPrice()">
          <template v-slot:label>
            <div>
              La maille, le boitier et le manchon de mon zip sont en bon état, seul le curseur est à changer
              <v-btn class="btn-invisible" @click.stop="showModal('zip')">
                <v-icon color="black darken-2" dark small>
                  mdi-information
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-if="displayEtanche" v-model="isEtanche" @click="getPrice()">
          <template v-slot:label>
            <div>
              Le zip d'origine est étanche et je souhaite conserver un zip étanche
              <v-btn class="btn-invisible" @click.stop="showModal('zip-etanche')">
                <v-icon color="black darken-2" dark small>
                  mdi-information
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12">
        <image-preview
          :is-bonus-eligible="bonus > 0"
          :value="pictures"
          @change="v => pictures = v"
        />
      </v-col>
      <price :amount="price" :bonus="bonus" :display-bottom-line="!!emplacement"/>
    </v-row>
    <bonus-image-confirm
      v-if="bonus > 0 && pictures.length < 2"
      @skip-bonus="bonus = 0"
    />
    <confirm-flow
      v-else-if="(emplacement || isCursor) && flux.indexEditProduit === null"
      @finalize="noarticle"
      @new-repair="onSelectNewRepair"
      @new-article="noarticle(false)"
    />
    <v-row v-else-if="this.flux.indexEditProduit">
      <v-col class="text-center" cols="12">
        <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import zipPrice from '../../assets/data/price/zip.json';
import Price from "@/components/shared/Price.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import Picto from "@/components/shared/Picto.vue";
import {roundPrice} from "@/helper/PriceHelper";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      emplacement: null,
      requireRules: [
        v => !!v || 'Ce champs est requis'
      ],
      poche_emplacement_labels: [
        'Poche intérieure gauche',
        'Poche intérieure droite',
        'Poche extérieure gauche',
        'Poche extérieure droite',
      ],
      poche_emplacements: [],
      selected_poche_emplacements: [],
      autre_emplacement: null,
      isModalVisible: false,
      isCursor: false,
      isEtanche: false,
      price: 0,
      bonus: 0,
      labels: [],
      oneUpdate: false,
      description_manager: null,
      pictures: []
    }
  },
  created() {
    this.labels = zipPrice[0][this.flux.level2].labels
    //Recup valeur champ si mode Edition
    if (this.flux.indexEditProduit !== null) {
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.poche_emplacements = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['poche_emplacements']
      this.autre_emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['autre_emplacement']
      this.isCursor = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isCursor']
      this.isEtanche = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isEtanche']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
    }
  },
  updated() {
    if (this.flux.componentName !== 'zip') {
      return null;
    }
    //Recup valeur champ si mode Edition
    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.labels = zipPrice[0][this.flux.level2].labels
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.poche_emplacements = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['poche_emplacements']
      this.autre_emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['autre_emplacement']
      this.isCursor = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isCursor']
      this.isEtanche = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isEtanche']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
    displayCursor() {
      return this.flux.level1 !== 'Bas' && this.flux.level2 !== 'POLO/T.SHIRT/BLOUSE' && this.flux.brand !== 'jott'
    },
    displayEtanche() {
      return !this.isCursor && (this.flux.level1 === 'Manteau/veste' || ['SAC A DOS/SAC SOUPLE', 'ACCESSOIRES DIVERS'].includes(this.flux.level2))
    },
    displayPocheOptions() {
      return this.emplacement === 'poche' && !['SAC A DOS/SAC SOUPLE', 'ACCESSOIRES DIVERS'].includes(this.flux.level2)
    },
    textAreaLabel() {
      return this.emplacement == 'autre' ||
      (this.emplacement === 'poche' && ['SAC A DOS/SAC SOUPLE', 'ACCESSOIRES DIVERS'].includes(this.flux.level2))
        ? 'EMPLACEMENT'
        : 'COMMENTAIRE'
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement(value) {
      if (value == 'scroll3') {
        this.$scrollTo('#screen3')
      }
      if (value == 'scroll1') {
        this.$scrollTo('#screen1')
      }
    },
    noarticle(showCart = true) {
      if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.showModal('cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, {
          id: liste_reparations[index].id,
          type: 'zip',
          componentName: 'zip',
          emplacement: this.emplacement,
          poche_emplacements: this.poche_emplacements,
          autre_emplacement: this.autre_emplacement,
          isCursor: this.isCursor,
          isEtanche: this.isEtanche,
          qte: 1,
          qteFinal: this.emplacement === 'poche' ? this.poche_emplacements.length : 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          description: this.getDescription(),
          pictures: this.pictures
        })

        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations,
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
      }
    },
    storeProduct() {
      if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        liste_reparations.push({
          id: this.flux.cptReparation,
          type: 'zip',
          componentName: 'zip',
          emplacement: this.emplacement,
          poche_emplacements: this.poche_emplacements,
          autre_emplacement: this.autre_emplacement,
          isCursor: this.isCursor,
          isEtanche: this.isEtanche,
          qte: 1,
          qteFinal: this.emplacement === 'poche' ? this.poche_emplacements.length : 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          description: this.getDescription(),
          pictures: this.pictures
        })

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal', this.flux.level2)
    },
    getPrice() {
      if (this.isCursor === true) {
        this.price = zipPrice[1]['curseur']
        this.bonus = 0
      } else {
        this.price = zipPrice[0][this.flux.level2][this.emplacement] ?? 0
        this.bonus = zipPrice[0][this.flux.level2]?.bonus?.[this.emplacement] ?? 0

        if (this.isEtanche) {
          this.price += zipPrice[1]['etanche']
        }
      }

      if (this.emplacement === 'poche' && this.poche_emplacements.length > 0) {
        this.price = this.price * this.poche_emplacements.length
        this.bonus = this.bonus * this.poche_emplacements.length
      }

      if (!this.isCursor && this.flux.isDouble) {
        this.price = this.price * 1.2
        this.bonus = zipPrice[0][this.flux.level2]?.bonus?.[`${this.emplacement}_double`] ?? this.bonus
      }

      if ((this.isCursor || this.isEtanche) && !this.emplacement) {
        this.emplacement = this.labels[0].value
      }

      this.price = roundPrice(this.price)

    },
    getDescription() {
      let description_manager = ''
      description_manager += '<b>Emplacement</b> : ' + this.emplacement + '<br />';
      if (this.autre_emplacement && this.emplacement === 'autre') {
        description_manager += '<b>Précision</b> : ' + this.autre_emplacement + '<br />'
      }
      if (this.poche_emplacements.length > 0 && this.emplacement === 'poche') {
        description_manager += '<b>Précision</b> : ' + this.poche_emplacements.join(', ') + '<br />'
      }
      if (this.isCursor) {
        description_manager += 'Attention, seul le curseur est à changer<br />'
      }
      if (this.isEtanche && !this.isCursor) {
        description_manager += 'Attention, remplacer par un zip étanche<br />'
      }
      return description_manager
    },
    onClickPocheEmplacement(label) {
      if (!this.selected_poche_emplacements.includes(label)) {
        this.selected_poche_emplacements.push(label)
      } else {
        this.poche_emplacements = this.poche_emplacements.filter(item => item !== label)
        this.selected_poche_emplacements = this.selected_poche_emplacements.filter(item => item !== label)
      }

      this.getPrice()
    }
  },
  components: {
    ConfirmFlow,
    Picto,
    BonusImageConfirm,
    ImagePreview,
    Price
  }
}
</script>
<style scoped>
table {
  max-width: 390px;
}

table td.picto, table td.picto img {
  width: 100px;
}

table td.picto {
  padding: 20px 20px 20px 0;
}

.btn {
  background-color: #000;
  width: 120px;
  padding: 10px;
  color: #fff
}

.grey {
  background-color: #F8F8F8 !important;
}
</style>
